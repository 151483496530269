<template>
  <section id="dashboard-ecommerce">
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="primary"
      opacity="0.85"
      blur="2"
      rounded="sm"
    >
      <div>
        <b-card no-body class="mb-0">
          <b-table
            :striped="true"
            :bordered="true"
            :hover="true"
            ref="refUserListTable"
            class="position-relative"
            :items="extracts"
            responsive
            :fields="tableColumns"
            primary-key="docNo"
            sort-by.sync="vDate"
            show-empty
            empty-text="Kayıt bulunamadı."
            head-variant="dark"
          >
            <template #cell(docNo)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ data.item.docNo }}
              </div>
            </template>
            <template #cell(debtPriceFormatted)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ data.item.debtPriceFormatted }} {{ data.item.rate }}
              </div>
            </template>
            <template #cell(willTakePriceFormatted)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ data.item.willTakePriceFormatted }} {{ data.item.rate }}
              </div>
            </template>
            <template #cell(balanceFormatted)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ data.item.balanceFormatted }} {{ data.item.rate }}
              </div>
            </template>
            <template #cell(date)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ format(new Date(data.item.date)) }}
              </div>
            </template>
            <template #cell(vDate)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ format(new Date(data.item.vDate)) }}
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="4"
                sm="4"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Toplam kayıt sayısı:<strong> {{ extractsCount }}</strong>
                </span>
              </b-col>
               <b-col
                cols="4"
                sm="4"
                class="d-flex align-items-center justify-content-center justify-content-sm-center"
              >
               <download-excel
                  :data="allExtracts"
                  type="xls"
                  name="CariEkstrelerim.xls"
                  :fields="json_fields"
                  worksheet="CariEkstrelerim"
                >
                  <b-button
                    style="margin-top: 0px"
                    type="button"
                    variant="primary"
                  >
                    <feather-icon icon="DownloadCloudIcon" class="mr-50" />
                    <span class="align-middle">Excel Olarak İndir</span>
                  </b-button>
                </download-excel>
              </b-col>
              <b-col
                cols="4"
                sm="4"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <div>
                  <h6 class="mb-2">
                    <strong> Toplamlar</strong>
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          <strong> Toplam Borç Tutarı (Toplam Satılan) :</strong>
                        </td>
                        <td>
                          {{ this.totalDebtPriceFormatted }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <strong> Toplam Alacak Tutarı (Toplam Ödenenen / İade) :</strong>
                        </td>
                        <td>{{ this.totalWillTakePriceFormatted }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <strong> Kalan Bakiye :</strong>
                        </td>
                        <td>{{ this.totalBalanceFormatted }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- <b-pagination
                  v-model="currentPage"
                  :total-rows="extractsCount"
                  per-page="50"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                    @input="changePage(currentPage)"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination> -->
              </b-col>
             
            </b-row>
          </div>
        </b-card>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormInput,
  BOverlay,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BImg,
    BCol,
    BCard,
    BModal,
    BOverlay,
    BFormInput,
    BCardHeader,
    BCardBody,
    BButton,
    BFormSpinbutton,
    vSelect,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination
  },
  data() {
    return {
      extracts: [],
      allExtracts: [],
      extractsCount: 0,
      tableColumns: [
        { key: "docNo", label: "Evrak No" },
        { key: "docType", label: "Evrak Tipi" },
        { key: "description", label: "Açıklama" },
        { key: "debtPriceFormatted", label: "Borç Fiyat" },
        { key: "willTakePriceFormatted", label: "Alacak Fiyat" },
        { key: "balanceFormatted", label: "Bakiye" },
        { key: "date", label: "Tarih" },
        { key: "vDate", label: "Valor Tarih" }
      ],
      ordersCount: 0,
      currentPage: 1,
      show: false,
      totalDebtPrice: 0,
      totalWillTakePrice: 0,
      totalBalance: 0,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      json_fields: {
        EvrakNo: "docNo",
        EvrakTipi: "docType",
        "Açıklama": "description",
        BorçFiyat: "debtPriceFormatted",
        AlacakFiyat: "willTakePriceFormatted",
        Bakiye: "balanceFormatted",
        Tarih: {
          field: "date",
          callback: value => {
            return this.format(new Date(value));
          }
        },
        ValorTarih: {
          field: "vDate",
          callback: value => {
            return this.format(new Date(value));
          }
        },
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.show = true;
      this.$http
        .get("/User/GetERPCurrentExtract")
        .then(response => {
          this.allExtracts = response.data.result.items;
          this.extracts = this.allExtracts;
          this.extractsCount = this.allExtracts.length;

          this.totalDebtPriceFormatted = response.data.result.totalDebtPriceFormatted;

          this.totalWillTakePriceFormatted = response.data.result.totalWillTakePriceFormatted;

          this.totalBalanceFormatted =response.data.result.totalBalancePriceFormatted;

          this.show = false;
        })
        .catch(error => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    changePage(cPage) {
      const skip = (cPage - 1) * 50;
      const take = skip + 50;
      console.log(skip + "," + take);
      this.extracts = this.allExtracts.slice(skip, take);
    },
    format(date) {
      var month = date.toLocaleString("tr-TR", { month: "short" });
      return date.getDate() + " " + month + " " + date.getFullYear();
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
